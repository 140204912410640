import React from "react";
import FeedItem from "./FeedItem";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { API_ENDPOINTS } from './config';

const Feed = ({ items = [], hasMore = false, onLoadMore, isLoadingMore = false }) => {
  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto", padding: 2 }}>
      {items.length === 0 ? (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="body1" color="text.secondary">
            No items to display. Check back later!
          </Typography>
        </Box>
      ) : (
        <>
          {items.filter(item => item && item.type).map((item, index) => (
            <FeedItem key={`${item.type}-${item.id}`} item={item} />
          ))}

          {hasMore && (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              {isLoadingMore ? (
                <CircularProgress size={24} sx={{ color: 'primary.main' }} />
              ) : (
                <Button 
                  variant="outlined" 
                  onClick={onLoadMore}
                  sx={{ 
                    textTransform: 'none',
                    px: 4,
                    py: 1
                  }}
                >
                  Load More
                </Button>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Feed;
