import * as React from 'react'
import { Link } from "react-router-dom";
import { Box, Image } from "@chakra-ui/react"
import { useContext } from 'react';
import { Context } from './provider';

export function AirbnbExample(props) {
  const { setQuiz } = useContext(Context);

  const handleClick = () => {
    setQuiz(props.quiz);
  };

  return (
    <Box 
      maxW='sm' 
      borderWidth='1px' 
      borderRadius='lg' 
      overflow='hidden'
      boxShadow='0 4px 6px rgba(0, 0, 0, 0.1)'
      transition='all 0.3s ease'
      _hover={{
        transform: 'translateY(-5px)',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)'
      }}
      bg='white'
      width="300px"
      onClick={handleClick}
    >
      <Link to="/quiz">
        <Box 
          position="relative" 
          width="100%" 
          paddingBottom="66.67%" 
          overflow="hidden"
        >
          <Image
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            objectFit="cover"
            src={props.imageUrl}
            alt={props.title}
          />
        </Box>

        <Box p="6">
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {props.title}
          </Box>

          <Box>
            {props.questions} Questions
            <Box as="span" color="gray.600" fontSize="sm">
              / {props.points} Points
            </Box>
          </Box>

          <Box display="flex" mt="2" alignItems="center">
            <Box as="span" ml="2" color="gray.600" fontSize="sm">
              {props.type}
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
