import React, { useContext, useState } from 'react';
import Quiz from "react-quiz-component";
import { Context } from "./provider";
import "./quiz.css";
import { Link } from "react-router-dom";
import { API_ENDPOINTS } from './config';

const QuizStart = () => {
  const { quiz: contextQuiz } = useContext(Context);
  const [quizResult, setQuizResultState] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [questionsCompleted, setQuestionsCompleted] = useState(0);

  const handleAnswerSubmit = async (questionIndex, selectedAnswer, isCorrect) => {
    try {
      const response = await fetch(API_ENDPOINTS.QUIZ_RESPONSES(contextQuiz.id), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: 'user123', // TODO: Get actual user ID
          questionIndex,
          selectedAnswer,
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setTotalScore(result.totalScore);
      setQuestionsCompleted(result.questionsCompleted);

      // If this was the last question, save the final result
      if (questionIndex === contextQuiz.questions.length - 1) {
        setQuizResultState({
          correctPoints: result.totalScore,
          totalPoints: contextQuiz.questions.reduce((sum, q) => sum + parseInt(q.point), 0),
          questionsCompleted: result.questionsCompleted,
          totalQuestions: contextQuiz.questions.length
        });
      }

      setCurrentQuestion(questionIndex + 1);
    } catch (error) {
      console.error('Error submitting answer:', error);
    }
  };

  // If no quiz is selected, show a message
  if (!contextQuiz) {
    return (
      <div style={{ margin: "100px", textAlign: "center" }}>
        <h2>No quiz selected</h2>
        <div style={{ margin: "20px" }}>
          <Link to="/quizzes">
            <button>Back to All Quizzes</button>
          </Link>
        </div>
      </div>
    );
  }

  // If quiz is completed, show the result
  if (quizResult) {
    const percentage = (quizResult.correctPoints / quizResult.totalPoints) * 100;
    const message = percentage >= 80 ? "Excellent!" : 
                   percentage >= 60 ? "Good job!" : 
                   percentage >= 40 ? "Not bad!" : 
                   "Keep practicing!";

    return (
      <div style={{ 
        margin: "100px", 
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#f5f5f5",
        borderRadius: "10px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
      }}>
        <h2 style={{ color: "#2c3e50", marginBottom: "20px" }}>Quiz Complete!</h2>
        <div style={{ 
          backgroundColor: "white", 
          padding: "20px", 
          borderRadius: "8px",
          marginBottom: "20px"
        }}>
          <h3 style={{ color: "#3498db", marginBottom: "10px" }}>{message}</h3>
          <p style={{ fontSize: "24px", color: "#2c3e50", marginBottom: "10px" }}>
            Your Score: {quizResult.correctPoints} / {quizResult.totalPoints}
          </p>
          <p style={{ fontSize: "18px", color: "#7f8c8d" }}>
            Percentage: {percentage.toFixed(1)}%
          </p>
        </div>
        <div style={{ 
          display: "flex", 
          justifyContent: "center", 
          gap: "10px" 
        }}>
          <Link to="/quizzes">
            <button style={{
              padding: "10px 20px",
              backgroundColor: "#3498db",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px"
            }}>
              Back to All Quizzes
            </button>
          </Link>
          <button 
            onClick={() => {
              setQuizResultState(null);
              setCurrentQuestion(0);
              setTotalScore(0);
              setQuestionsCompleted(0);
            }} 
            style={{
              padding: "10px 20px",
              backgroundColor: "#2ecc71",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px"
            }}>
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div style={{ margin: "100px" }}>
      <div className="outerQuiz">
        <Quiz
          style={{
            alignItems: "center",
            border: "20px solid lightgray",
            justifyContent: "center",
            borderBottom: "solid 1px",
            paddingBottom: "1rem",
            display: "flex",
          }}
          quiz={contextQuiz}
          showDefaultResult={false}
          onQuestionSubmit={handleAnswerSubmit}
          showInstantFeedback={true}
          continueTillCorrect={false}
        />
      </div>

      <div style={{
        margin: "20px",
        padding: "20px",
        backgroundColor: "#f8f9fa",
        borderRadius: "10px",
        textAlign: "center"
      }}>
        <p style={{ fontSize: "18px", color: "#2c3e50" }}>
          Questions Completed: {questionsCompleted} / {contextQuiz.questions.length}
        </p>
        <p style={{ fontSize: "18px", color: "#2c3e50" }}>
          Current Score: {totalScore}
        </p>
      </div>

      <div
        style={{
          margin: "20px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link to="/quizzes">
          <button>Back to All Quizzes</button>
        </Link>
      </div>
    </div>
  );
};

export default QuizStart;
