import './index.css';
import BottomNavigation from '@mui/material/BottomNavigation';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import QuizStart from './QuizStart';
import QuizPage from './QuizPage';
import Provider from './provider';
import ReactGA from 'react-ga';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import AppBar from "./AppBar";
import CategoryPoll from './CategoryPoll';
import PollResponses from './PollResponses';

// Initialize Google Analytics
ReactGA.initialize('G-LDS1MC2BQJ');

// Create a function to track page views
const trackPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

function App() {
  console.log('App component rendering'); // Debug log

  return (
    <Provider>
      <BrowserRouter>
        <AppBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/quiz" element={<QuizStart />} />
          <Route path="/yo-ki-tyo" element={<CategoryPoll />} />  
          <Route path="/quizzes" element={<QuizPage />} />
          <Route path="/poll-responses" element={<PollResponses />} />
        </Routes>
        <BottomNavigation />
      </BrowserRouter>
    </Provider>
  );
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Track initial page view
trackPageView();

// Track page views on route changes
window.addEventListener('popstate', trackPageView);