import React, { useState, useEffect } from "react";
import axios from "axios";
import { Rings } from "react-loader-spinner";
import DataTable from "./dataTable";
import { Box, Typography, Container } from "@mui/material";

function PollResponses() {
  const [pollData, setPollData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`https://9opesuegjh.execute-api.us-east-1.amazonaws.com/test/`)
      .then((res) => {
        const items = res.data;
        items.forEach((item) => {
          item.time = new Date(item.time).toDateString();
        });
        setPollData(items);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching polls:', error);
        setIsLoading(false);
      });
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography 
        variant="h4" 
        component="h1" 
        align="center"
        sx={{ 
          mb: 4,
          fontWeight: 500,
          color: 'text.primary'
        }}
      >
        Poll Responses
      </Typography>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <Rings color="#00BFFF" height={80} width={80} />
        </Box>
      ) : (
        <Box sx={{ 
          width: '100%',
          overflowX: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 1
        }}>
          <DataTable data={pollData} />
        </Box>
      )}
    </Container>
  );
}

export default PollResponses;
