import React, { useState, useEffect, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
} from '@mui/material';
import Confetti from 'react-confetti';
import { motion } from 'framer-motion';
import { API_ENDPOINTS } from './config';

const CategoryPoll = () => {
  const [polls, setPolls] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [remainingOptions, setRemainingOptions] = useState([]);
  const [winner, setWinner] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);

  // Fetch polls when component mounts
  useEffect(() => {
    fetchPolls();
  }, []);

  const fetchPolls = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.POLLS);
      if (!response.ok) {
        throw new Error('Failed to fetch polls');
      }
      const data = await response.json();
      
      // Transform the data into the expected format
      const categorizedPolls = data.reduce((acc, poll) => {
        if (!acc[poll.category]) {
          acc[poll.category] = {
            question: poll.question,
            options: poll.options
          };
        }
        return acc;
      }, {});
      
      setPolls(categorizedPolls);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching polls:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Shuffle upon selecting a new category
  useEffect(() => {
    if (selectedCategory && polls[selectedCategory]) {
      const options = [...polls[selectedCategory].options];
      shuffleArray(options);
      setCurrentOptions([options[0], options[1]]);
      setRemainingOptions(options.slice(2));
    }
  }, [selectedCategory, polls]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const handleChoice = async (chosenOption) => {
    const notChosenOption = currentOptions.find(opt => opt !== chosenOption);

    // Send vote to API
    try {
      const response = await fetch(`${API_ENDPOINTS.POLLS}/${selectedCategory}/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          option: chosenOption,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit vote');
      }

      // If there are remaining options, continue the battle
      if (remainingOptions.length > 0) {
        const newOptions = [...remainingOptions];
        const nextOption = newOptions.shift();
        setCurrentOptions([chosenOption, nextOption]);
        setRemainingOptions(newOptions);
      } else {
        // If no remaining options, we have a winner
        setWinner(chosenOption);
      }
    } catch (err) {
      console.error('Error submitting vote:', err);
      // Continue with the game even if vote submission fails
      if (remainingOptions.length > 0) {
        const newOptions = [...remainingOptions];
        const nextOption = newOptions.shift();
        setCurrentOptions([chosenOption, nextOption]);
        setRemainingOptions(newOptions);
      } else {
        setWinner(chosenOption);
      }
    }
  };

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        Loading polls...
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        color: 'red' 
      }}>
        Error: {error}
      </div>
    );
  }

  const resetPoll = () => {
    setSelectedCategory(null);
    setCurrentOptions([]);
    setWinner(null);
    setRemainingOptions([]);
  };

  // Minimal card style
  const minimalCardStyle = (category) => ({
    cursor: 'pointer',
    borderRadius: '8px',
    boxShadow: hoveredCard === category
      ? '0 6px 16px rgba(0,0,0,0.12)'
      : '0 2px 8px rgba(0,0,0,0.08)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    transform: hoveredCard === category ? 'translateY(-4px)' : 'none',
    backgroundColor: '#fff',
    overflow: 'hidden',
  });

  return (
    <div style={{ backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      

      {/* MAIN CONTAINER */}
      <div
        style={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '30px 20px',
        }}
      >
        {/* "YO ki TYO" as a heading */}
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          style={{ 
            fontWeight: '600', 
            color: '#1976d2', 
            marginBottom: '40px',
            letterSpacing: '1px'
          }}
        >
          YO ki TYO
        </Typography>

        {/* Categories grid */}
        {!selectedCategory ? (
          <motion.div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
              gap: '30px',
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {Object.keys(polls).map((category) => (
              <motion.div
                key={category}
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Card
                  onClick={() => setSelectedCategory(category)}
                  onMouseEnter={() => setHoveredCard(category)}
                  onMouseLeave={() => setHoveredCard(null)}
                  style={minimalCardStyle(category)}
                >
                  {/* Card Header in MUI Blue */}
                  <CardHeader
                    title={category}
                    titleTypographyProps={{
                      variant: 'h6',
                      align: 'center',
                      fontWeight: '600',
                      color: '#fff',
                    }}
                    style={{
                      backgroundColor: '#1976d2',
                      padding: '16px',
                    }}
                  />
                  {/* Card Content */}
                  <CardContent style={{ padding: '24px' }}>
                    <Typography
                      variant="body1"
                      paragraph
                      align="center"
                      style={{ color: '#555', marginBottom: '24px' }}
                    >
                      {polls[category].question}
                    </Typography>
                    <Button
                      variant="outlined"
                      fullWidth
                      style={{
                        color: '#1976d2',
                        borderColor: '#1976d2',
                        fontWeight: '500',
                        textTransform: 'none',
                        padding: '10px',
                        borderRadius: '4px',
                        '&:hover': {
                          backgroundColor: 'rgba(25, 118, 210, 0.04)',
                        },
                      }}
                    >
                      START THE SHOWDOWN!
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </motion.div>
        ) : winner ? (
          /* 2) If there's a winner */
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
              numberOfPieces={200}
            />
            <Card
              elevation={3}
              style={{
                padding: '20px',
                marginTop: '20px',
                textAlign: 'center',
                backgroundColor: '#fff',
                borderRadius: '8px',
              }}
            >
              <CardContent>
                <Typography variant="h5" paragraph>
                  Your top pick for
                </Typography>
                <Typography
                  variant="h6"
                  paragraph
                  style={{
                    fontStyle: 'italic',
                    color: '#777',
                    marginBottom: '20px',
                  }}
                >
                  &quot;{polls[selectedCategory].question}&quot;
                </Typography>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                >
                  <Typography
                    variant="h4"
                    paragraph
                    style={{
                      fontWeight: 'bold',
                      color: '#1976d2',
                      margin: '30px 0',
                    }}
                  >
                    {winner}
                  </Typography>
                </motion.div>
                <Button
                  variant="contained"
                  onClick={resetPoll}
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#1976d2',
                    color: '#fff',
                  }}
                >
                  Start a New Poll
                </Button>
              </CardContent>
            </Card>
          </motion.div>
        ) : (
          /* 3) Face-off stage */
          <div style={{ marginTop: '40px' }}>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              style={{ fontWeight: '600', color: '#333' }}
            >
              {selectedCategory} Face-off!
            </Typography>

            <Card
              elevation={3}
              style={{
                padding: '20px',
                marginBottom: '40px',
                backgroundColor: '#fff',
                borderRadius: '8px',
              }}
            >
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                style={{ color: '#1976d2', fontWeight: '600' }}
              >
                {polls[selectedCategory].question}
              </Typography>
            </Card>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              {currentOptions.map((option, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    flex: 1,
                    minWidth: '280px',
                    maxWidth: '320px',
                    margin: '10px 20px',
                  }}
                >
                  <Card
                    onClick={() => handleChoice(option)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#fff',
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                      transition: 'box-shadow 0.3s',
                    }}
                  >
                    <CardContent
                      style={{
                        height: '120px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        style={{
                          fontWeight: 'bold',
                          color: '#444',
                          textAlign: 'center',
                        }}
                      >
                        {option}
                      </Typography>
                    </CardContent>
                  </Card>
                </motion.div>
              ))}
            </div>

            <Typography
              variant="body2"
              align="center"
              style={{ marginTop: '20px', color: '#666' }}
            >
              Remaining options: {remainingOptions.length + 1}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryPoll;
