import React, { useState } from 'react';
import { Box, Typography, Stack, Chip, Button, Card, CardContent, LinearProgress } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { API_ENDPOINTS } from './config';
import axios from 'axios';

const FeedItem = ({ item }) => {
  const [submitted, setSubmitted] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [score, setScore] = useState(0);
  const [message, setMessage] = useState('');

  if (!item || !item.type || !item.data) {
    return null;
  }

  const handlePollSubmit = async (option) => {
    try {
      // Send only the required fields
      const pollResponse = {
        option,
        userId: 'anonymous'
      };
      
      const response = await axios.post(
        API_ENDPOINTS.POLL_VOTE(item.id),
        pollResponse
      );
      
      setHasVoted(true);
      if (response.data && response.data.data) {
        item.data.userResponse = option;
      }
      setMessage('Vote submitted successfully!');
      console.log('Vote submitted:', response.data);
    } catch (error) {
      console.error('Error submitting vote:', error);
      setMessage(error.response?.data?.error || 'Error submitting vote. Please try again.');
    }
  };

  const handleQuizSubmit = async (answer) => {
    try {
      console.log('Submitting answer:', {
        answer,
        correctAnswer: item.data.correctAnswer,
        quizId: item.id
      });

      const response = await axios.post(
        API_ENDPOINTS.QUIZ_RESPONSES(item.id),
        {
          userId: 'anonymous',
          selectedAnswer: answer,
          questionIndex: 0
        }
      );

      console.log('Quiz response:', response.data);
      
      setScore(response.data.score);
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting quiz answer:', error);
      setMessage('Error submitting answer');
    }
  };

  const renderContent = () => {
    switch (item.type) {
      case 'fact':
        return (
          <Box>
            {item.data.imageUrl && (
              <Box 
                component="img"
                src={item.data.imageUrl}
                alt={item.data.title}
                sx={{ 
                  width: '100%',
                  height: 'auto',
                  borderRadius: 1,
                  mb: 2
                }}
              />
            )}
            {item.data.tags && item.data.tags.length > 0 && (
              <Stack direction="row" spacing={1} mt={2}>
                {item.data.tags.map((tag, index) => (
                  <Chip key={index} label={tag} size="small" variant="outlined" sx={{ bgcolor: '#f5f5f5' }} />
                ))}
              </Stack>
            )}
          </Box>
        );

      case 'poll':
        return (
          <Box>
            <Stack 
              spacing={1} 
              direction="column" 
              sx={{ mt: 2 }}
            >
              {item.data.options.map((option, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => handlePollSubmit(option)}
                  sx={{ 
                    textTransform: 'none',
                    borderColor: '#1976d2',
                    color: '#1976d2',
                    '&:hover': {
                      borderColor: '#1976d2',
                      backgroundColor: 'rgba(25, 118, 210, 0.04)'
                    }
                  }}
                >
                  {option}
                </Button>
              ))}
            </Stack>
            {hasVoted ? (
              <Typography variant="body2" color="success.main" sx={{ mt: 2 }}>
                Thanks for voting! You selected: {item.data.userResponse}
              </Typography>
            ) : null}
            {message && (
              <Typography 
                variant="body2" 
                color={message.includes('Error') ? 'error' : 'success.main'}
                sx={{ mt: 2 }}
              >
                {message}
              </Typography>
            )}
            {item.data.tags && item.data.tags.length > 0 && (
              <Stack direction="row" spacing={1} mt={2}>
                {item.data.tags.map((tag, index) => (
                  <Chip key={index} label={tag} size="small" variant="outlined" sx={{ bgcolor: '#f5f5f5' }} />
                ))}
              </Stack>
            )}
          </Box>
        );

      case 'quiz':
        return (
          <Box>
            {/* Question */}
            <Typography 
              variant="h6" 
              sx={{ 
                color: 'rgb(17, 24, 39)',
                mb: 3,
                fontWeight: 500,
                lineHeight: 1.4
              }}
            >
              {item.data.question}
            </Typography>

            {/* Result Message */}
            {submitted ? (
              <Box 
                sx={{ 
                  bgcolor: score > 0 ? 'rgb(240, 253, 244)' : 'rgb(254, 242, 242)',
                  borderRadius: '12px',
                  p: 3,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 2
                }}
              >
                {score > 0 ? (
                  <CheckCircle sx={{ color: 'rgb(34, 197, 94)', fontSize: 24 }} />
                ) : (
                  <Cancel sx={{ color: 'rgb(220, 38, 38)', fontSize: 24 }} />
                )}
                <Box>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      color: score > 0 ? 'rgb(34, 197, 94)' : 'rgb(220, 38, 38)',
                      fontWeight: 600,
                      mb: 0.5
                    }}
                  >
                    {score > 0 ? 'Correct!' : 'Incorrect'}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ color: 'rgb(75, 85, 99)' }}
                  >
                    {score > 0 
                      ? `Your answer: ${selectedAnswer}`
                      : `The correct answer was ${item.data.correctAnswer}`
                    }
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Stack spacing={1.5}>
                {item.data.options && item.data.options.map((option, index) => (
                  <Button
                    key={index}
                    variant={selectedAnswer === option ? "contained" : "outlined"}
                    onClick={() => {
                      setSelectedAnswer(option);
                      handleQuizSubmit(option);
                    }}
                    sx={{ 
                      textTransform: 'none',
                      py: 1.5,
                      borderColor: '#e5e7eb',
                      color: selectedAnswer === option ? 'white' : 'rgb(17, 24, 39)',
                      '&:hover': {
                        borderColor: '#d1d5db',
                        bgcolor: selectedAnswer === option ? 'primary.main' : 'rgba(0, 0, 0, 0.04)'
                      }
                    }}
                  >
                    {option}
                  </Button>
                ))}
              </Stack>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Card sx={{ mb: 2, p: 3, borderRadius: 2, boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
      <CardContent>
        {/* Display the badge/label */}
        {item.displayType && (
          <Chip 
            label={item.displayType} 
            size="small" 
            color="primary" 
            sx={{ 
              mb: 2,
              borderRadius: '16px',
              color: 'white',
              fontWeight: 500
            }} 
          />
        )}
        
        {/* Title */}
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ 
            mb: 2,
            fontSize: '1.1rem',
            fontWeight: 500,
            lineHeight: 1.4,
            color: '#1976d2'
          }}
        >
          {item.data.title || item.data.question}
        </Typography>
        
        {/* Content */}
        {renderContent()}
      </CardContent>
    </Card>
  );
};

export default FeedItem;
