import React, { createContext, useState, useCallback } from 'react';
import { API_ENDPOINTS } from './config';

export const Context = createContext();

const Provider = ({ children }) => {
  const [quiz, setQuiz] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchRandomQuiz = useCallback(async () => {
    if (quiz) return; // Don't fetch if we already have a quiz
    
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch(API_ENDPOINTS.QUIZZES);
      if (!response.ok) {
        throw new Error('Failed to fetch quizzes');
      }
      const quizzes = await response.json();
      const randomNum = Math.floor(Math.random() * quizzes.length);
      setQuiz(quizzes[randomNum]);
    } catch (err) {
      console.error('Error fetching quizzes:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [quiz]);

  const value = {
    quiz,
    setQuiz,
    isLoading,
    error,
    fetchRandomQuiz
  };

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
