const API_BASE_URL = 'https://jxu7o6dtbd.execute-api.us-east-1.amazonaws.com';

export const API_ENDPOINTS = {
  FEED: `${API_BASE_URL}/feed`,
  FACTS: `${API_BASE_URL}/facts`,
  QUIZZES: `${API_BASE_URL}/quizzes`,
  QUIZ_RESPONSES: (quizId) => `${API_BASE_URL}/quizzes/${quizId}/responses`,
  POLLS: `${API_BASE_URL}/polls`,
  POLL_VOTE: (pollId) => `${API_BASE_URL}/polls/${pollId}/vote`
};

export default API_ENDPOINTS;
