import React, { useState, useEffect } from "react";
import axios from "axios";
import { Rings } from "react-loader-spinner";
import { Context } from "./context";
import usePageTracking from "./usePageTracking";
import Feed from "./Feed";
import { API_ENDPOINTS } from './config';

function Home() {
  usePageTracking();
  const [feedItems, setFeedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadFeed = async (pageToken = null) => {
    try {
      const isFirstLoad = !pageToken;
      if (isFirstLoad) {
        setIsLoading(true);
      } else {
        setIsLoadingMore(true);
      }

      const url = pageToken 
        ? `${API_ENDPOINTS.FEED}?lastEvaluatedKey=${encodeURIComponent(pageToken)}`
        : API_ENDPOINTS.FEED;

      console.log(`Fetching feed with URL: ${url}`);
      const response = await axios.get(url);
      console.log('Feed response:', response.data);
      
      const { items = [], nextPage } = response.data;
      
      // Transform items to include all necessary data
      const transformedItems = items.map(item => {
        if (item.type === 'quiz') {
          return {
            ...item,
            data: {
              ...item.data,
              answers: item.data.answers || [],
              quizId: item.data.quizId,
              questionIndex: item.data.questionIndex
            }
          };
        }
        if (item.type === 'poll') {
          return {
            ...item,
            data: {
              ...item.data,
              options: item.data.options || [],
              pollId: item.data.id
            }
          };
        }
        return item;
      });
      
      if (isFirstLoad) {
        setFeedItems(transformedItems);
      } else {
        setFeedItems(prev => [...prev, ...transformedItems]);
      }
      
      setNextPageToken(nextPage);
      setError(null);
    } catch (error) {
      console.error('Error fetching feed:', error);
      setError(error.response?.data?.error || 'Error loading feed');
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    loadFeed();
  }, []);

  const handleLoadMore = () => {
    if (nextPageToken && !isLoadingMore) {
      loadFeed(nextPageToken);
    }
  };

  return (
    <Context.Provider value={{ feedItems }}>
      <div
        className="HomeContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1200px",
          margin: "20px auto",
          padding: "0 20px",
        }}
      >
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <Rings color="#00BFFF" height={80} width={80} />
          </div>
        ) : error ? (
          <div style={{ textAlign: 'center', color: 'red', marginTop: '20px' }}>
            {error}
          </div>
        ) : (
          <Feed 
            items={feedItems} 
            hasMore={!!nextPageToken} 
            onLoadMore={handleLoadMore}
            isLoadingMore={isLoadingMore}
          />
        )}
      </div>
    </Context.Provider>
  );
}

export default Home;