import './App.css';
import React, { useState, useEffect } from 'react';
import { AirbnbExample } from './card.js';
import usePageTracking from './usePageTracking';
import { API_ENDPOINTS } from './config';

const QuizPage = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  usePageTracking();

  useEffect(() => {
    fetchQuizzes();
  }, []);

  const fetchQuizzes = async () => {
    try {
      // Get quiz sets only
      const response = await fetch(`${API_ENDPOINTS.QUIZZES}?type=set`);
      if (!response.ok) {
        throw new Error('Failed to fetch quizzes');
      }
      const data = await response.json();
      setQuizzes(data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching quizzes:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  const handleQuizClick = (quiz) => {
    console.log('Starting quiz:', quiz.quizTitle);
  };

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        Loading quizzes...
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        color: 'red' 
      }}>
        Error: {error}
      </div>
    );
  }

  return (
    <div className="HomeContainer" style={{ 
      display: 'flex', 
      flexDirection: 'column',
      padding: '20px'
    }}>
      <h1 style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        width: '100%', 
        margin: '20px 0',
        fontSize: '2.5rem',
        color: '#2c3e50'
      }}>
        Quizzes
      </h1>

      <div style={{ 
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '30px',
        padding: '20px',
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto'
      }}>
        {quizzes.map((item) => (
          <div key={item.id} style={{ 
            display: 'flex',
            justifyContent: 'center'
          }}>
            <AirbnbExample
              shadow="base"
              _hover={{ color: 'green.500', shadow: '2xl' }}
              type={item.quizSynopsis}
              title={item.quizTitle}
              questions={item.questions.length}
              imageUrl={item.imageUrl}
              quiz={item}
              onClick={() => handleQuizClick(item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizPage;